<template>
    <div class="home home-event">
        <div v-if="this.isFetching == false">
            <div v-if="this.event.imageBanner[0]" class="image-banner">
                <img :src="this.event.imageBanner[0].url">
            </div>  

            <div v-if="this.event.thumbnail[0]" class="daily-thumbnail" style="display: none;">
                <img :src="this.event.thumbnail[0].url">
            </div>  

            <div v-if="this.event.imageBanner[0]" class="image-banner-spacer"></div>
            <div v-else class="image-banner-spacer-small"></div>

            <div class="info-event">
                <h2 v-if="this.event.title">{{this.event.title}}</h2>
                <p v-if="this.event.postSummary">{{ this.event.postSummary}}</p>
            </div>

            <div class="home-feed">

                <div v-if="this.event.thumbnail[0]" class="daily-thumbnail" style="display: block;">
                    <img :src="this.event.thumbnail[0].url">
                </div>  

                <!-- <LiveFeedDaily v-bind:callObject="callObject" v-if="callObject && eventStatus == 'live' " />
                <LiveFeedPast v-bind:callObject="callObject" v-bind:videoUrl="videoUrl" v-if="eventStatus == 'past' && videoUrl " />
                <LiveFeedFuture v-bind:callObject="callObject" v-bind:eventStartDate="eventStartDate" v-if="eventStatus == 'future' " /> -->

                <div v-if="this.event.eventDownloads.length > 0" class="home-downloads">
                    <div class="section-header">
                        <h1>DOWNLOADS</h1>
                    </div>
                    <div class="content-downloads">

                        <div v-for="(download, item) in this.event.eventDownloads" v-bind:key="item">
                            <a :href="download.url" download>
                                <button class="btn-download">{{download.title}}, <br> (.{{download.extension}}, {{humanFileSize(download.size)}})</button>
                            </a>
                        </div>

                    </div>
                </div>

                <div v-if="this.event.description" class="home-about-event">
                    <div class="section-header">
                        <h1>ABOUT EVENT</h1>
                    </div>
                    <div class="content-info">
                        <p v-html="this.event.description"></p>
                    </div>
                </div>
            </div>
            
            <div v-if="this.event.wineListId" class="home-products home-products--1col">
                <ProductFeed v-bind:wineListId="wineListId"/>
            </div>
        </div>

        <div v-else class="lds-ring">
            <img class="loading-gif" src="@/assets/img/loadingwheel.gif" alt="">
        </div>
    </div>
</template>
<script>
import store from '@/store/index.js'

// import LiveFeedDaily from '@/components/LiveFeedDaily';
// import LiveFeedPast from '@/components/LiveFeedPast';
// import LiveFeedFuture from '@/components/LiveFeedFuture';

import ProductFeed from '@/components/ProductFeed';
import gql from 'graphql-tag';
// import DailyIframe from '@daily-co/daily-js';
import moment from 'moment';
import $ from 'jquery';

export default {
    name: "Event",
    components: {
        // LiveFeedDaily,
        // LiveFeedPast,
        // LiveFeedFuture,
        ProductFeed
    },

    data() {
        return {
            isFetching: true,
            eventId: this.$route.params.eventId,
            event: [],
            eventStatus: "",
            callObject: null,
            eventStartDate: Object,
            videoUrl: "",
            wineListId: ""
        }
    },

    mounted() {
        this.eventId = this.$route.params.eventId;
        store.commit('setvisitedEventId', this.eventId);

        $('.wrapper').attr('style', '');
        $('.wrapper').css('position', 'unset');
    },

    methods: {
        humanFileSize(size) {
            console.log(size);
            var i = Math.floor( Math.log(size) / Math.log(1000) );
            return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
        },

        // createRoom() {
        //     console.log("Starting room");

        //     // Set view model (this)
        //     var vm = this;

        //     var url = "https://api.daily.co/v1/rooms/";
        //     var eventName = vm.event.slug.substring(0, 8);

        //     var xhr = new XMLHttpRequest();
        //     xhr.open("POST", url);

        //     xhr.setRequestHeader("Content-Type", "application/json");
        //     xhr.setRequestHeader("Authorization", "Bearer 260a34e726ede2a1b6eb89c6faf6fbd9aecae14abcb1fd65a049ca1904a0bcf8");

        //     xhr.onreadystatechange = function () {
        //         if (xhr.readyState === 4) {
        //             console.log("Room created");
        //             console.log(xhr.status);
        //             console.log(xhr.responseText);

        //             const ROOM_URL = 'https://californiawines.daily.co/' + eventName;

        //             const call = DailyIframe.createCallObject({
        //                 url: ROOM_URL,
        //                 audioSource: true,
        //                 videoSource: true,
        //                 dailyConfig: {
        //                     experimentalChromeVideoMuteLightOff: true,
        //                 },
        //             });

        //             console.log(call + "");

        //             vm.callObject = call;
        //         }
        //     };

        //     var data = `{"name": ` + '"'+ eventName + '"' + `,
        //         "privacy": "public",
        //         "properties" : {
        //             "start_audio_off":false,
        //             "start_video_off":false}}`;

        //     xhr.send(data);
        // }
    },

    apollo: {
        entry: {
            client: 'craft',
            query: gql`query ($eventId: [QueryArgument]) {
                entry(id: $eventId, status: "disabled") {
                    ... on videoConferences_videoConferences_Entry {
                        id
                        slug
                        title
                        postSummary
                        imageBanner {
                            url
                        }
                        thumbnail {
                            url @transform (width: 1200, immediately: true)
                        }
                        eventDate
                        eventStartTime
                        eventEndTime
                        eventDownloads {
                            url
                            title
                            extension
                            size
                        }
                        description
                        videoEmbed
                        wineListId
                    }
                }
            }`,

            // pollInterval: 60000, //ms
            fetchPolicy: 'network-only',

            variables() {
                return {
                    eventId: this.eventId
                }
            },

            result ({ data, loading }) {
                console.log("Results");

                if (!loading) {
                    console.log(data);

                    // Remmove html tags from description
                    if (data.entry.postSummary) {
                        data.entry.postSummary = data.entry.postSummary.replace(/<(?!a\s*\/?)[^>]+>/g, '')
                    }

                    if (data.entry.description) {
                        data.entry.description = data.entry.description.replace(/<(?!a\s*\/?)[^>]+>/g, '')
                    }

                    this.isFetching     = false;
                    this.event          = data.entry;

                    // localStart var is used for local timezones outside of UK
                    // moment.utc() converts to UTC whereas moment() uses local time

                    var localStart;
                    var start = this.event.eventStartTime;
                    var end   = this.event.eventEndTime;
                    var date  = this.event.eventDate;

                    localStart      = moment(start).format('HH:mm');
                    start           = moment.utc(start).format('HH:mm');
                    end             = moment.utc(end).format('HH:mm');
                    date            = moment.utc(date).format('L');

                    var localStartDate      = moment(date + ' ' + localStart, 'MM/DD/YYYY HH:mm');
                    var startDate           = moment(date + ' ' + start, 'MM/DD/YYYY HH:mm');
                    var endDate             = moment(date + ' ' + end, 'MM/DD/YYYY HH:mm');

                    this.eventStartDate = localStartDate;

                    var timeUTC = moment.tz("Europe/London").format('MM/DD/YYYY HH:mm');

                    this.wineListId = data.entry.wineListId;
                    store.commit('setwineListId', this.wineListId);
                    store.commit('setvisitedEvent', data.entry.slug);

                    if (moment(endDate) < moment(timeUTC)) {
                        console.log("Past event");
                        this.eventStatus = "past";
                    } 
                    
                    else if (moment(startDate) > moment(timeUTC)) {
                        console.log("Future event");
                        this.eventStatus = "future";
                        // this.createRoom();
                    } 
                    
                    else if (moment(startDate) < moment(timeUTC) && moment(endDate) > moment(timeUTC)) {
                        console.log("Live event");
                        this.eventStatus = "live";
                        // this.createRoom(); 
                    }
                }

            },
        }
    }
}
</script>